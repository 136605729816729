import React, { useEffect } from "react"
import Layout from "../../components/layout"
import { graphql, Link } from "gatsby";
import '../../styles/Article.css'
import { DefaultGtag } from '../../utils/misc'


const mainStyles = {
  display: "flex",
  flexDirection: "column"
}

const ArticlePage = () => {

  useEffect(() => {
    setTimeout(function () {
      // need delay, otherwise not working on firefox...
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, 100);
  }, [])

  return (
    <Layout>
      <main style={mainStyles}>
        <div className="blogpage">
          <div className="blog">
            <h1 className="title">Should my restaurant use digital menus?</h1>
            <p>Digital menus are becoming more and more popular in restaurants, and for good reason. Not only do they offer a more modern and user-friendly experience for customers, but they can also provide benefits for restaurant owners and staff. In this blog post, we will discuss the reasons why your restaurant should consider using digital menus.</p>
            <h2>Easy to update and change</h2>
            <p>One of the main advantages of digital menus is that they are easy to update and change. With a traditional paper menu, making changes can be time-consuming and costly. You would need to print out new menus and then distribute them to each table, which can be a hassle. With a digital menu, you can simply make the changes on your computer and they will be reflected on the screens at each table. This means that you can easily update prices, add new items, or remove items that are no longer available.</p>
            <h2>More engaging for customers</h2>
            <p>Another benefit of digital menus is that they can provide a more engaging experience for customers. With a traditional paper menu, customers may simply scan the items and make their selection without giving the menu much thought. With a digital menu, you can use images and videos to showcase your dishes, which can make them more appealing and encourage customers to try new items. You can also use the digital menu to provide additional information about each dish, such as ingredients, allergens, and nutritional information. This can be especially helpful for customers with dietary restrictions or preferences.</p>
            <h2>Improved operational efficiency</h2>
            <p>Digital menus can also help to improve the efficiency of your restaurant's operations. With a traditional paper menu, servers have to manually write down each customer's order and then bring it to the kitchen. This can lead to mistakes and misunderstandings, which can cause delays and frustrations for both the staff and the customers. With a digital menu, servers can simply input the orders directly into a computer or tablet, which can then be sent directly to the kitchen. This can help to reduce errors and ensure that orders are prepared accurately and quickly.</p>
            <h2>Save money</h2>
            <p>In addition to the benefits mentioned above, digital menus can also help to save money for restaurant owners. Printed menus have to be regularly updated and replaced, which can add up to significant costs over time. Digital menus, on the other hand, can be used for an indefinite period of time and only require a one-time investment. This can help to save on printing and distribution costs, and can also reduce the amount of paper waste generated by your restaurant.</p>
            <h2>Conclusion</h2>
            <p>In conclusion, digital menus offer a number of benefits for restaurants, including the ability to easily update and change the menu, provide a more engaging experience for customers, improve operational efficiency, and save money. If your restaurant is still using a traditional paper menu, it may be time to consider switching to a digital menu. If are looking where to start, consider creating an account at <Link to="/solutions/digital-menu/" style={{ color: "#2cb052", fontWeight: "bold" }}>Take a Seat</Link>, all in self-service for you to get started right now. No demo meeting required, no technical skill required and awesome support.</p>
            <br />
            <br />
            <h2>About the author</h2>
            <p>This article if offered by Take a Seat, the digital partner of restaurants.</p>
            <p>We work to keep our articles short and to the point. If you would like more information, see menu examples, or need help setting up your QR code menus, feel free to contact our team at <a rel="nofollow" href="mailto:team@takeaseat.io" style={{ color: "blue" }}>team@takeaseat.io</a>. </p>
            <p style={{ paddingBottom: "3rem" }}>To create your restaurant's menu with us, visit our website at <a rel="author" href="https://takeaseat.io" style={{ color: "blue" }}>takeaseat.io</a>, it takes only 5 minutes from start to finish!</p>
          </div>
        </div>
      </main>
    </Layout >
  )
}

export default ArticlePage

export const Head = () => <>
  <DefaultGtag />
  <link rel="canonical" href="https://takeaseat.io/article/should-my-restaurant-use-digital-menus/" />

  <title>Should my restaurant use digital menus?</title>
  <meta name="description" content="Should you make the switch to digital menus? In this post, we highlight the main reasons why restaurants are making the change and why you should to." />

  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://takeaseat.io/static/media/images/all-in-one-6" />
  <meta property="og:site_name" content="Blog of Take a Seat" />
  <meta property="og:title" content="Should my restaurant use digital menus?" />
  <meta property="og:description" content="Should you make the switch to digital menus? In this post, we highlight the main reasons why restaurants are making the change and why you should to." />
  <meta property="og:url" content="https://takeaseat.io/article/should-my-restaurant-use-digital-menus/" />

  <meta name="twitter:card" content="summary" />
  <meta name="twitter:image" content="https://pbs.twimg.com/media/FEjpZgbXIAoTpq4?format=jpg&name=4096x4096" />
  <meta name="twitter:site" content="@Takeaseat_io" />
  <meta name="twitter:creator" content="@Takeaseat_io" />
  <meta name="twitter:title" content="Should my restaurant use digital menus?" />
  <meta name="twitter:description" content="Should you make the switch to digital menus? In this post, we highlight the main reasons why restaurants are making the change and why you should to." />
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "http://schema.org",
      "@type": "BlogPosting",
      "headline": "Should my restaurant use digital menus?",
      "author": "Take a Seat",
      "description": "Should you make the switch to digital menus? In this post, we highlight the main reasons why restaurants are making the change and why you should to.",
      "url": "https://takeaseat.io/article/should-my-restaurant-use-digital-menus/",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://takeaseat.io/article/should-my-restaurant-use-digital-menus/"
      },
      "datePublished": "2022-05-30",
      "dateModified": "2022-09-30",
      "publisher": {
        "@type": "Organization",
        "name": "Take a Seat",
        "logo": {
          "@type": "ImageObject",
          "url": "https://takeaseat.io/media/icons/tas-230427.svg"
        }
      },
      "image": {
        "@type": "ImageObject",
        "url": "https://takeaseat.io/static/media/images/go-digital.jpg"
      }
    })}
  </script>
</>

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["cookies","index", "navigation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;